import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import heroBg from "../../../images/general-pos-og.png";
import LeadForm from "../../../components/Forms/LeadForm";
import data, { generalPosData } from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import bg1 from "../../../images/svg/vector-10.svg";
import b1 from "../../../images/random/badge-101.png";
import b2 from "../../../images/random/badge-102.png";
import b3 from "../../../images/random/badge-103.png";
import b4 from "../../../images/random/badge-104.png";
import b5 from "../../../images/random/badge-105.png";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import TopRestaurantsLogos from "../../../components-v2/TopRestaurantsLogos";
import Image from "../../../components/ImageQuerys/RandomImages";
import { ONLINE_ORDERING } from "../../../utils/firebase/forms";
import check from "../../../images/svg/checkmark.svg";
import reviews from "../../../data/reviews";

function OnlineOrdering({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Restaurant Online Ordering Promo | SpotOn"
        description="Get 5 months free and take control of your online ordering with SpotOn Order. Commission-free online ordering menu. POS integration. Customizable pacing. Learn more."
        image={`https://spoton.com/${heroBg}`}
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading level={1} injectionType={1} withBlueSymbol>
            Commission-free, fee-free online ordering. Now get 5 months free
          </Heading>
          <div className="text-black-300 text-[20px] max-w-[400px] mt-10 mx-auto lg:mx-0 relative z-1">
            Get the online ordering system that puts you back in control of your
            take-out and delivery offerings.
          </div>

          <div>
            <Heading
              withBlueSymbol={false}
              level={2}
              injectionType={4}
              className="text-black max-w-[800px] mx-auto mt-8 relative z-1 hidden md:block"
            >
              Committed to helping your business grow through innovation and
              unmatched customer support.
            </Heading>
            <div className="mt-8 md:mt-0 mx-auto flex flex-wrap gap-3 items-center justify-between md:justify-between lg:justify-start xl:justify-between">
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b1}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b2}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b3}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b4}
                alt="badge"
              />
              <img
                className="hidden md:inline max-w-[22%] md:max-w-[100px]"
                src={b5}
                alt="badge"
              />
            </div>
          </div>
        </div>

        <div className="relative">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={data}
            initialValues={extractDefaultValues(data)}
            onValuesChange={() => null}
            leadType="restaurants"
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterest=""
            collection={ONLINE_ORDERING}
            thankYouPath="/lp/onlineordering/thank-you"
          />
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <Section fullWidth className="bg-black mt-10">
        <div className="text-white grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-4 max-w-[1000px] mx-auto lg:py-40">
          <div className="text-center mx-auto w-full lg:mx-0 lg:text-left">
            <Heading
              className="text-white"
              withBlueSymbol
              level={2}
              injectionType={1}
            >
              Online ordering. On your terms
            </Heading>

            <p className="text-black-600 text-p lg:max-w-[380px]">
              While apps and other POS providers eat into your profits with
              commissions—or worse, charge your guests fees—SpotOn is in your
              corner.
            </p>
            <div className="font-bold lg:max-w-[400px] gap-y-6 flex flex-col mt-8">
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Online ordering menu for guests to order directly from your
                  website and Google
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Direct POS integration, order pacing, 2-way text messaging,
                  and QR codes
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Delivery integrations for CaptainAI and flat-rate DoorDash
                  delivery
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Optional loyalty program to drive repeat visits and increase
                  revenue
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Real humans to set it all up for you, with 24/7 support after
                  launch
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  And no commissions or bogus fees
                </span>
              </div>
            </div>
          </div>

          <div className="relative -mb-32 ">
            <Image
              imageName="online-ordering-asset.png"
              alt="products"
              className="relative lg:absolute left-0 right-0 top-0"
            />
          </div>
        </div>
      </Section>

      <Section className="mt-40 xl:mt-48">
        <TestmonialReviews
          sectionData={{
            reviews,
            title: "What SpotOn customers are saying",
            subTitle: "Read some of our 1500+ five star customer reviews.",
          }}
        />
      </Section>

      <TopRestaurantsLogos
        desktopImageName="online-ordering-logos-desktop.png"
        mobileImageName="online-ordering-logos-mobile.png"
      />
    </Layout>
  );
}

export default OnlineOrdering;
